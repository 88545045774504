.photo-popup-content {
    text-align: center;
  }
  
  .photo-popup-content .photo-popup-image {
    height: 100%;
    max-width: 100%;
  }
  
  .dx-icon-overflow:before {
    color: #000000;
  }

  .dx-texteditor-container  {
    height: 32px;
  }

  .jss5 {
    flex-direction: column !important;
  }

  .jss2 {
    width: 280px !important;
    border: none !important;
  }
    